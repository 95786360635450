import React from 'react';
import { GdrpContext } from './GdprContext';
import classnames from 'classnames';

type Props = {
    serviceKey: string;
    children: any;
}

export default function GdprAllowButton (props: Props) {
    const { serviceKey, children } = props;

    const { dispatch } = React.useContext(GdrpContext);

    const onClick = () => {
        dispatch({
            type: "SAVE_SETTINGS",
            settings: {
                [serviceKey]: true,
            }
        })
    }

    return <div onClick={onClick}>{children}</div>



};