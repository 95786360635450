import React, { useState } from "react"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"

import { useStaticQuery, graphql } from "gatsby"

import { FaInfoCircle, FaExclamationTriangle } from "react-icons/fa"
import "./forms.scss"

export default function CampForm() {
  const { register, handleSubmit, errors, reset } = useForm()
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const token = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            formToken
          }
        }
      }
    `
  ).site.siteMetadata.formToken

  const onSubmit = data => {
    if (data.name !== "" && data.name !== undefined) {
      return
    }
    setLoading(true)

    fetch(`https://smjadmin.jhgo.de/api/forms/submit/camp?token=${token}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        form: {
          Email: data.email,
          "================ Kind ================": "",
          Vorname: data.firstName,
          Nachname: data.lastName,
          Geburtstag: data.birthday,
          Adresse:
            data.street +
            " " +
            data.houseNumber +
            " " +
            data.plz +
            " " +
            data.city,
          Lieblingsessen: data.favFood,
          "Wunsch für Zeltbelegung": data.friends,
          Schwimmen: data.swim,
          Sonstiges: data.specialInfos,
          "================ Eltern ================": "",
          Hauptversicherte: data.insuredLastName + " " + data.insuredFirstName,
          Telefon: data.phone,
          Email: data.email,
          Krankenkasse: data.healthInsurance,
          Versicherungsnummer: data.insuranceNumber,
          "=== >>> Bestätigung per...  <<< ===": data.receipt,
        },
      }),
    }).then(response => {
      if (response.ok) {
        setRequestError(false)
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        reset()
      } else {
        setLoading(false)
        console.log("ERROR", response)
        setRequestError(true)
      }
    })
  }

  return (
    <form className="campForm" onSubmit={handleSubmit(onSubmit)}>
      {/* --- Vorname --- */}
      <div class="columns">
        <div className="field column is-half">
          <label class="label">Vorname des Kindes*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.firstName ? "is-danger" : "")}
              name="firstName"
              ref={register({ required: true })}
            />
          </div>
          {errors.firstName && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie den Vorname Ihres Kindes an.
            </p>
          )}
        </div>
        {/* --- Nachname --- */}
        <div className="field column is-half">
          <label class="label">Nachname des Kindes*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.lastName ? "is-danger" : "")}
              name="lastName"
              ref={register({ required: true })}
            />
          </div>
          {errors.lastName && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie den Nachname Ihres Kindes an.
            </p>
          )}
        </div>
      </div>
      {/* --- Addresse --- */}
      <div className="address columns">
        <div className="field column is-half street">
          <label class="label">Straße*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.street ? "is-danger" : "")}
              name="street"
              ref={register({ required: true })}
            />
          </div>
          {errors.street && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie Ihre Straße an.
            </p>
          )}
        </div>
        <div className="field column is-half houseNumber">
          <label class="label">Hausnummer*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.houseNumber ? "is-danger" : "")}
              name="houseNumber"
              ref={register({ required: true })}
            />
          </div>
          {errors.houseNumber && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie Ihre Hausnummer an.
            </p>
          )}
        </div>
      </div>
      <div className="address columns">
        <div className="field column is-half city">
          <label class="label">Ort*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.city ? "is-danger" : "")}
              name="city"
              ref={register({ required: true })}
            />
          </div>
          {errors.city && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie Ihren Ort an.
            </p>
          )}
        </div>
        <div className="field column is-half plz">
          <label class="label">PLZ*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.plz ? "is-danger" : "")}
              name="plz"
              ref={register({ required: true })}
            />
          </div>
          {errors.plz && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie Ihren Postleitzahl an.
            </p>
          )}
        </div>
      </div>
      {/* --- Birthday --- */}
      <div className="field birthday">
        <label class="label">Geburtstag*</label>
        <div className="control">
          <input
            type="date"
            className={"input " + (errors.birthday ? "is-danger" : "")}
            name="birthday"
            ref={register({ required: true })}
          />
        </div>
        {errors.birthday && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie den Geburtstag Ihres Kindes an.
          </p>
        )}
      </div>
      {/* --- Lieblingsessen --- */}
      <div className="field">
        <label class="label">Lieblingsessen</label>
        <div className="control">
          <input
            type="text"
            className="input"
            name="favFood"
            ref={register()}
          />
        </div>
      </div>
      {/* --- Wunsch für Zeltbelegung --- */}
      <div className="field">
        <label class="label">Wunsch für Zeltbelegung</label>
        <div className="control">
          <input
            type="text"
            className={"input"}
            name="friends"
            ref={register()}
          />
        </div>
      </div>
      {/* --- Phone --- */}
      <div className="field">
        <label class="label">
          Telefonnummer* (erreichbar während dem Zeltlager)
        </label>
        <div className="control">
          <input
            type="text"
            className={"input " + (errors.phone ? "is-danger" : "")}
            name="phone"
            ref={register({ required: true })}
          />
        </div>
        {errors.phone && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie Ihre Telefonnummer an.
          </p>
        )}
      </div>
      {/* --- Email --- */}
      <div className="field">
        <div className="control">
          <label class="label">Email*</label>
          <input
            type="text"
            className={"input " + (errors.email ? "is-danger" : "")}
            name="email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
        </div>
        {errors.email && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie eine gültige Email an.
          </p>
        )}
      </div>
      {/* --- Tetanus --- */}
      <div className="field">
        <label class="label">Letzte Tetanusimpfung*</label>
        <div className="control">
          <input
            type="date"
            className={"input " + (errors.tetanus ? "is-danger" : "")}
            name="tetanus"
            ref={register({ required: true })}
          />
        </div>
        {errors.tetanus && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie die letzte Tetanusimpfung Ihres Kindes an.
          </p>
        )}
      </div>
      {/* --- Health Insurance --- */}
      <div className="health columns is-multiline">
        <div className="field column is-half">
          <label class="label">Krankenkasse*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.healthInsurance ? "is-danger" : "")}
              name="healthInsurance"
              ref={register({ required: true })}
            />
          </div>
          {errors.healthInsurance && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie Ihre Krankenkasse an.
            </p>
          )}
        </div>
        {/* --- Insurance number --- */}
        <div className="field column is-half">
          <label class="label">Versicherungsnummer*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.insuranceNumber ? "is-danger" : "")}
              name="insuranceNumber"
              ref={register({ required: true })}
            />
          </div>
          {errors.insuranceNumber && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie die Versicherungsnummer an.
            </p>
          )}
        </div>
        {/* --- Vorname --- */}
        <div className="field column is-half">
          <label class="label">Vorname des Hauptversicherten*</label>
          <div className="control">
            <input
              type="text"
              className={
                "input " + (errors.insuredFirstName ? "is-danger" : "")
              }
              name="insuredFirstName"
              ref={register({ required: true })}
            />
          </div>
          {errors.insuredFirstName && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie den Vorname des Hauptversicherten an.
            </p>
          )}
        </div>
        {/* --- Nachname --- */}
        <div className="field column is-half">
          <label class="label">Nachname des Hauptversicherten*</label>
          <div className="control">
            <input
              type="text"
              className={"input " + (errors.insuredLastName ? "is-danger" : "")}
              name="insuredLastName"
              ref={register({ required: true })}
            />
          </div>
          {errors.insuredLastName && (
            <p class="help is-danger">
              <FaInfoCircle className="faIcon" />
              Bitte geben Sie den Nachname des Hauptversicherten an.
            </p>
          )}
        </div>
      </div>

      {/* --- Sonstiges --- */}
      <div className="columns">
        <div className="field column is-full">
          <label class="label">Sonstiges</label>
          <div className="control">
            <textarea
              name="specialInfos"
              placeholder="Allergien, Medikamente, besondere Essenskost, usw."
              className="textarea"
              ref={register()}
            />
          </div>
        </div>
      </div>
      <div className="extra columns is-multiline">
        {/* --- Swimming --- */}
        <div className="field column is-half">
          <label class="label">
            Wir erlauben unserem Sohn in Begleitung eines Gruppenleiters...
          </label>
          <div className="control">
            <div className="select">
              <select name="swim" ref={register({ required: true })}>
                <option value="nicht erlaubt">nicht zu schwimmen</option>
                <option value="erlaubt">zu schwimmen</option>
              </select>
            </div>
          </div>
        </div>
        {/* --- Receipt --- */}
        <div className="field column is-half">
          <label class="label">Wir möchten die Anmeldebestätigung...</label>
          <div className="control">
            <div className="select">
              <select name="receipt" ref={register({ required: true })}>
                <option value="Email">per Email</option>
                <option value="Post">per Post</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* --- Privacy --- */}
      <div className="legal">
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                type="checkbox"
                placeholder="Datenschutz"
                name="privacy_policy"
                className={errors.privacy_policy ? "is-danger" : ""}
                ref={register({ required: true })}
              />
              <span className={errors.privacy_policy ? "has-text-danger" : ""}>
                Die abgesendeten Daten werden nur zum Zweck der Bearbeitung
                Ihres Anliegens verarbeitet. Weitere Informationen finden Sie in
                unserer <Link to={`/datenschutz`}>Datenschutzerklärung</Link>.
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* --- Submit --- */}
      <div class="control">
        <button
          className={
            "button is-fullwidth " +
            (loading ? " is-loading " : "") +
            (requestError ? " is-danger " : " is-primary")
          }
          type="submit"
        >
          {!requestError ? (
            "Verbindlich Anmelden"
          ) : (
            <>
              <FaExclamationTriangle className="faIcon" /> Aktuell können keine
              Anfragen bearbeitet werden!
            </>
          )}
        </button>
      </div>
      {/* --- Honey Pot --- */}
      <div className="field myName">
        <label className="label">Name</label>
        <div className="control">
          <input
            type="text"
            className={"input " + (errors.name ? "is-danger" : "")}
            name="name"
            ref={register()}
          />
        </div>
        {errors.name && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie Ihren Namen an.
          </p>
        )}
      </div>
    </form>
  )
}
