import React from "react"
import useGdprIsAllowed from "../Gdpr/useGdprIsAllowed"
import * as styles from "./Video.module.scss"
import classnames from "classnames"
import GdprAllowButton from "../Gdpr/GdprAllowButton"
import GdprSettings from "../Gdpr/GdprSettings"
import GdprDialogButton from "../Gdpr/GdprDialogButton"

type Props = {
  src: string
}
export default function Video(props: Props) {
  const { src } = props
  const isAllowed = useGdprIsAllowed("Youtube")

  return (
    <div className={styles.videoWrapper}>
      {!isAllowed && (
        <div className={classnames([styles.noAccess, styles.iframe])}>
          <div className="content">
            <p className={styles.title}>
              Um dieses Video zu schauen benötigen wir Ihre Zustimmung in der
              Privatsphäre.
            </p>
            <GdprAllowButton serviceKey="Youtube">
              <button className="button is-primary is-small">
                Erlauben und Video anzeigen
              </button>
            </GdprAllowButton>
            <GdprDialogButton />
          </div>
        </div>
      )}

      {isAllowed && (
        <iframe
          className={styles.iframe}
          width="560"
          height="315"
          src={src}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      )}
    </div>
  )
}
